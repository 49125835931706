<nav class="ml-2 flex justify-between" aria-label="Breadcrumb">
  <ol class="inline-flex items-center">
    <li
      *ngFor="
        let breadcrumb of breadcrumbs$ | async;
        let last = last;
        let first = first
      ">
      <div class="flex items-center">
        <a
          [routerLink]="breadcrumb.url"
          class="group inline-flex cursor-pointer items-center rounded px-3 py-2 text-center transition-all duration-100 hover:bg-primary-50 hover:text-primary-500 hover:ring-1 hover:ring-primary-500 dark:ring-primary-500/80 dark:hover:bg-primary-950">
          @if (first && breadcrumb.icon) {
            <fa-icon
              class="pr-3 group-hover:text-primary-500"
              [icon]="breadcrumb.icon" />
          }
          {{ breadcrumb.title }}
        </a>
        @if (!last) {
          <span class="mx-2 font-medium text-neutral-500">/</span>
        }
      </div>
    </li>
  </ol>
</nav>
