import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HasRoleDirective } from '@core/directives/has-role.directive';
import { Role } from '@core/states/auth/enums/role';
import {
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faBookmark,
  faChartLine,
  faCode,
  faCodeFork,
  faDiagramProject,
  faFingerprint,
  faGaugeHigh,
  faLock,
  faPuzzlePiece,
  faRocket,
  faScrewdriver,
  faSpaceShuttle,
  faTerminal,
  faUser,
  faUserAstronaut,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { SidebarItemComponent } from './sidebar-item.component';
import { IsFeatureEnabledDirective } from '@core/feature-preview/directives/is-feature-enabled.directive';
import { SidebarToggleComponent } from '@core/components/sidebar/components/sidebar-toggle.component';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'app-sidebar-content',
  imports: [
    CommonModule,
    FaIconComponent,
    HasRoleDirective,
    IsFeatureEnabledDirective,
    RouterLink,
    SidebarItemComponent,
    SidebarToggleComponent,
  ],
  styles: [
    ':host { @apply relative z-50 flex flex-col overflow-visible shadow; } ',
  ],
  template: `
    <aside
      class="bg--default h-screen w-full overflow-x-hidden"
      aria-label="Sidebar">
      <div
        class="border--default relative flex h-[95%] flex-col border-r px-3 py-1">
        <div class="flex flex-grow flex-col gap-y-8 font-medium">
          <div class="flex flex-col">
            <ul>
              <app-sidebar-item
                *appIsFeatureEnabled="'Dashboard'"
                [icon]="['fas', 'chart-line']"
                label="Dashboard"
                path="dashboard" />
              <app-sidebar-item
                *appIsFeatureEnabled="'Workflow'"
                [icon]="['fas', 'diagram-project']"
                label="Workflows"
                path="workflows" />
              <app-sidebar-item
                [icon]="['fas', 'code-fork']"
                label="Processes"
                path="process-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'rocket']"
                label="Executed Processes"
                path="process-instances" />
              <app-sidebar-item
                [icon]="['fas', 'fingerprint']"
                label="Triggers"
                path="detection-type-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'space-shuttle']"
                label="Executed Triggers"
                path="detection-instances" />
            </ul>
          </div>

          <div class="flex flex-col">
            <div
              class="text--backdrop pl-2 text-sm uppercase leading-6"
              [class.visible]="!collapsed()"
              [class.invisible]="collapsed()">
              <span>Settings</span>
            </div>
            <ul>
              <app-sidebar-item
                [icon]="['fas', 'user']"
                label="Users"
                path="users" />
              <app-sidebar-item
                [icon]="['fas', 'users']"
                label="Tenants"
                path="tenants" />
            </ul>
          </div>

          <div class="flex flex-col" *appHasRole="[Role.ROLE_SUPER_ADMIN]">
            <div
              class="text--backdrop flex gap-2 pl-2 text-sm uppercase leading-6"
              [class.visible]="!collapsed()"
              [class.invisible]="collapsed()">
              <span>Staff</span>
              <fa-icon [icon]="'lock'" class="text-xs"></fa-icon>
            </div>
            <ul>
              <app-sidebar-item
                [icon]="['fas', 'user-astronaut']"
                label="App Configuration"
                path="application-configurations" />
              <app-sidebar-item
                [icon]="['fas', 'puzzle-piece']"
                label="Components"
                path="component-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'bookmark']"
                label="Tags"
                path="tags" />
              <app-sidebar-item
                [icon]="['fas', 'gauge-high']"
                label="Process Throttles"
                path="process-throttles" />
            </ul>
          </div>
        </div>
        <app-sidebar-toggle
          [class.self-end]="!collapsed()"
          [class.self-center]="collapsed()" />
      </div>
    </aside>
  `,
})
export class SidebarContentComponent {
  /**
   * PROPERTIES
   */
  protected readonly Role = Role;
  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);

  constructor(
    private library: FaIconLibrary,
    private sidebarService: SidebarService
  ) {
    this.registerIcons();
  }

  private registerIcons() {
    this.library.addIcons(
      faBookmark,
      faCodeFork,
      faRocket,
      faGaugeHigh,
      faFingerprint,
      faSpaceShuttle,
      faUsers,
      faUser,
      faScrewdriver,
      faTerminal,
      faCode,
      faUserAstronaut,
      faPuzzlePiece,
      faChartLine,
      faLock,
      faDiagramProject
    );
  }
}
