<div class="relative flex size-full flex-grow flex-col overflow-clip">
  <app-particles />
  <section
    class="bg--default flow flex size-full flex-col items-center justify-center gap-y-12 overflow-clip">
    <div
      class="absolute inset-0 z-10 animate-pulse-slow bg-radial-primary"></div>
    <img
      class="w-full max-w-72"
      [ngSrc]="thumbnail()"
      width="126"
      height="150"
      alt="flowzilla gorilla"
      priority />

    <div class="z-30 w-96">
      <ng-content />
    </div>
  </section>
</div>
