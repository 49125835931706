import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ClickOutsideDirective } from '@core/directives/clickoutside.directive';

@Component({
  standalone: true,
  selector: 'app-nav-menu-trigger',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ClickOutsideDirective],
  template: `
    <span
      class="group inline-block size-8 cursor-pointer overflow-hidden rounded-full bg-neutral-100 dark:bg-neutral-800"
      appClickOutside
      (clickOutside)="onCloseMenu()"
      (click)="onTrigger()">
      <svg
        class="h-full w-full {{
          isOpen()
            ? 'text-neutral-400 dark:text-neutral-500 '
            : 'text-neutral-400 dark:text-neutral-600 group-hover:text-neutral-400 dark:group-hover:text-neutral-500'
        }}"
        fill="currentColor"
        viewBox="0 0 24 24">
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  `,
})
export class NavMenuTriggerComponent {
  isOpen = signal(false);

  onTrigger() {
    this.isOpen.set(!this.isOpen());
  }

  onCloseMenu() {
    this.isOpen.set(false);
  }
}
